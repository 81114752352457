export class RevisionHistory {

	id: number;
	title: string;
	description: string;
	date: string;
	linkText: string;
	updatedBy: string;
	updatedDate: number;
	linksArray: string[];

	constructor(revisionHistory: RevisionHistory) {
		Object.assign(this, revisionHistory);
	}
}

export const emptyRevision = {
	'id': 0,
	'title': '',
	'description': '',
	'date': '',
	'linkText': '',
	'updatedBy': '',
	'updatedDate': 0,
	'linksArray': ['']
};
