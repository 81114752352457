import {Injectable} from '@angular/core';
import {Config} from '../../../config';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RevisionHistory} from '../../../models/revision-history';

@Injectable({
	providedIn: 'root'
})
export class ManageRevisionHistoryService {

	private config = new Config();
	private httpUrl = `${this.config.url}/revisionHistory`;

	constructor(private http: HttpClient) {
	}

	findAll(): Observable<RevisionHistory[]> {
		return this.http.get(this.httpUrl).pipe(map((revisions: RevisionHistory[]) => {
			return revisions.map(revision => {
				return new RevisionHistory(revision);
			});
		}));
	}

	findById(id: number): Observable<RevisionHistory> {
		return this.http.get<RevisionHistory>(this.httpUrl + '/' + id);
	}

	create(revisionHistory: RevisionHistory): Observable<RevisionHistory> {
		return this.http.post<RevisionHistory>(this.httpUrl, revisionHistory);
	}

	update(id: number, revisionHistory: RevisionHistory): Observable<RevisionHistory> {
		return this.http.put<RevisionHistory>(this.httpUrl + '/' + id, revisionHistory);
	}

	delete(id: number) {
		return this.http.delete<boolean>(this.httpUrl + '/' + id);
	}
}
