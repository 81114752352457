import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import {AppRoutingModule} from './app-routing.module';
import {ExamplePageComponent} from './example-page/example-page.component';
import {OauthModule} from './oauth/oauth.module';
import {NotFoundComponent} from './not-found/not-found.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpRequestInterceptor} from './services/http-request-interceptor.service';
import {HomeComponent} from './home/home.component';
import {MasterModule} from './master/master.module';
import {ProcessNameService} from './services/master/process-name/process-name.service';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {TableModule} from 'primeng/table';
import {InputTextModule} from 'primeng/inputtext';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {ToastModule} from 'primeng/toast';

import {ButtonModule} from 'primeng/button';
import {AccordionModule} from 'primeng/accordion';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogService, MessageService} from 'primeng/api';
import {CarmessageService} from './services/message/carmessage.service';
import {HistoricalMfgPerfService} from './services/master/historical-mfgperf/historical-mfgperf.service';
import {ManageSharedLoadingService} from './services/master/manage-shared-loading/manage-shared-loading.service';
import {TooltipModule} from 'primeng/tooltip';
import {ViewCarRequestComponent} from './home/view-car-request/view-car-request.component';
import {CreateCarRequestComponent} from './home/create-car-request/create-car-request.component';
import {CarRequestService} from './services/request/car-request.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule, MatButtonModule, MatInputModule, MatStepperModule} from '@angular/material';
import {MatSelectModule} from '@angular/material/select';
import {FieldsetModule} from 'primeng/fieldset';
import {DropdownModule} from 'primeng/dropdown';
import {DialogModule} from 'primeng/dialog';
import {CreateCarProcessNameComponent} from './home/create-car-request/create-car-process-name/create-car-process-name.component';
import {
	AutoCompleteModule,
	CalendarModule,
	CardModule,
	ChartModule,
	InputMaskModule,
	InputTextareaModule, MultiSelectModule,
	PanelModule,
	ProgressSpinnerModule,
	RadioButtonModule,
	ToggleButtonModule
} from 'primeng/primeng';
import {ReversePipe} from './home/create-car-request/reverse.pipe';
import {ProcessTypeService} from './services/master/process-type/process-type.service';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {HelpComponent} from './help/help.component';
import {OeeChartComponent} from './home/create-car-request/oee-chart/oee-chart.component';
import {SharedLoadingChartsComponent} from './home/create-car-request/shared-loading-charts/shared-loading-charts.component';
import {HstmfgChartComponent} from './home/create-car-request/hstmfg-chart/hstmfg-chart.component';
import {SummaryProcessStreamComponent} from './home/create-car-request/summary-process-stream/summary-process-stream.component';
import {OndeleteDialogueboxComponent} from './ondelete-dialoguebox/ondelete-dialoguebox.component';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {NoAccessComponent} from './no-access/no-access.component';
import {QuickInfoComponent} from './home/create-car-request/quick-info/quick-info.component';
import {BrowserIncompatableComponent} from './browser-incompatable/browser-incompatable.component';
import {EcarPrintComponent} from './ecar-print/ecar-print.component';
import {DatePipe, TitleCasePipe} from '@angular/common';
import { ApsErrorPageComponent } from './oauth/aps-error-page.component';
import { EcarErrorPageComponent } from './oauth/ecar-error-page.component';


@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		ExamplePageComponent,
		NotFoundComponent,
		HomeComponent,
		CreateCarRequestComponent,
		ViewCarRequestComponent,
		CreateCarProcessNameComponent,
		ReversePipe,
		HelpComponent,
		OeeChartComponent,
		SharedLoadingChartsComponent,
		HstmfgChartComponent,
		SummaryProcessStreamComponent,
		OndeleteDialogueboxComponent,
		NoAccessComponent,
		QuickInfoComponent,
		BrowserIncompatableComponent,
		EcarPrintComponent,
		ApsErrorPageComponent,
		EcarErrorPageComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule,
		AppRoutingModule,
		OauthModule,
		MasterModule,
		MenuModule,
		MenubarModule,
		TableModule,
		InputTextModule,
		MessagesModule,
		MessageModule,
		ToastModule,
		ButtonModule,
		AccordionModule,
		CheckboxModule,
		TooltipModule,
		ReactiveFormsModule,
		MatStepperModule,
		MatInputModule,
		MatButtonModule,
		MatAutocompleteModule,
		MatSelectModule,
		FieldsetModule,
		FormsModule,
		DropdownModule,
		DialogModule,
		ToggleButtonModule,
		RadioButtonModule,
		PanelModule,
		CalendarModule,
		CardModule,
		ConfirmDialogModule,
		ChartModule,
		InputTextareaModule,
		DynamicDialogModule,
		InputTextareaModule,
		AutoCompleteModule,
		InputMaskModule,
		ProgressSpinnerModule,
		MultiSelectModule
	],
	exports: [MasterModule],
	providers: [ProcessNameService, ProcessTypeService, MessageService, CarmessageService, HistoricalMfgPerfService, ManageSharedLoadingService, CarRequestService, DialogService, Title, {
		provide: HTTP_INTERCEPTORS,
		useClass: HttpRequestInterceptor,
		multi: true
	}, DatePipe, TitleCasePipe],
	entryComponents: [
		OndeleteDialogueboxComponent
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
